import { defineStore } from 'pinia';
import { useLandmarkStore } from './landmarks';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkPoints from '@kitware/vtk.js/Common/Core/Points';
import vtkCellArray from '@kitware/vtk.js/Common/Core/CellArray';

interface Line {
  id: number;
  name: string;
  distance: number;
  landmarkAId: number;
  landmarkBId: number;
}

interface LineState {
  lines: Line[];
  lineActors: Map<number, { actor: vtkActor, mapper: vtkMapper, polyData: vtkPolyData }>;
  nextId: number;
  updated: boolean;
  hoveredLine: number | null;
}

export const useLineStore = defineStore('lines', {
  state: (): LineState => ({
    lines: [],
    lineActors: new Map<number, { actor: vtkActor, mapper: vtkMapper, polyData: vtkPolyData }>(),
    nextId: 1,
    updated: false,
    hoveredLine: null,
  }),
  actions: {
    addLine(name: string, landmarkAId: number, landmarkBId: number, distance: number = 0.0) {
      const id = this.nextId++;
      const line: Line = { id, name, landmarkAId, landmarkBId, distance };
      this.lines.push(line);
      this.updated = !this.updated;
      return line;
    },
    addLineActor(id: number, actor: vtkActor, mapper: vtkMapper, polyData: vtkPolyData) {
      this.lineActors.set(id, { actor, mapper, polyData });
    },
    getLines() {
      return this.lines;
    },
    getLineActors() {
      return this.lineActors;
    },
    removeLineActor(id: number) {
      this.lineActors.delete(id);
    },
    updateHoveredLine(id: number | null) {
      // Reset the previous hovered landmark
      if (this.hoveredLine !== null) {
        const actor = this.lineActors.get(this.hoveredLine)?.actor;
        actor?.getProperty().setLineWidth(1.0);
        actor?.modified();
      }

      // Update the hovered landmark
      this.hoveredLine = id;

      // Highlight the new hovered landmark
      if (id !== null) {
        const actor = this.lineActors.get(id)?.actor;
        actor?.getProperty().setLineWidth(3.0);
        actor?.modified();
      }
      
      this.updated = !this.updated;

    },
    removeAllLines() {
      // Get all line IDs
      const lineIds = this.lines.map(line => line.id);
    
      // Remove each line using the existing removeLine method
      lineIds.forEach(id => {
        this.removeLine(id);
      });
      
    },
    removeLine(id: number) {
      this.lines = this.lines.filter(line => line.id !== id);
      this.updated = !this.updated;
    },
    updateLineName(id: number, newName: string) {
      const line = this.lines.find(line => line.id === id);
      if (line) {
        line.name = newName;
        this.updated = !this.updated;
      }
    },
    getLineLandmarks(lineId: number) {
      const landmarkStore = useLandmarkStore();
      const line = this.lines.find(line => line.id === lineId);
      if (!line) return [];
      return [
        landmarkStore.getLandmarks().find(landmark => landmark.id === line.landmarkAId),
        landmarkStore.getLandmarks().find(landmark => landmark.id === line.landmarkBId),
      ];
    },

    // Display functionalities

    display(renderer: any) {
      // Remove old line actors if they exist
      this.lineActors.forEach(({ actor, mapper, polyData }, lineId) => {

        const lineExists = this.lines.some(line => line.id === lineId);

        if(!lineExists){
          if (actor) {
            renderer?.removeActor(actor);
          }
          if (mapper) {
            mapper.delete();
          }
          if (polyData) {
            polyData.delete();
          }
          this.removeLineActor(lineId);
        }
        
      });

      this.lines.forEach(line => {

        const actorExists = this.lineActors.has(line.id)

        if(actorExists){
          return
        }

        if(!actorExists){

          const landmarks = this.getLineLandmarks(line.id).filter(Boolean);
          const points = landmarks.map(({ x, y, z }) => [x, y, z]);

          if (points.length !== 2) {
            return;
          }

          const ps = vtkPoints.newInstance();
          const a = points[0];
          const b = points[1];
          ps.insertNextPoint(a[0], a[1], a[2]);
          ps.insertNextPoint(b[0], b[1], b[2]);

          const cells = vtkCellArray.newInstance();
          cells.insertNextCell([0, 1]); // Line connecting the two points

          const polyData = vtkPolyData.newInstance();
          polyData.setPoints(ps);
          polyData.setLines(cells);

          const lineMapper = vtkMapper.newInstance();
          lineMapper.setInputData(polyData);

          const lineActor = vtkActor.newInstance();
          lineActor.setMapper(lineMapper);
          lineActor.getProperty().setColor(0.0, 1.0, 0.0); // Green line
          lineActor.setPickable(false);

          renderer?.addActor(lineActor);
          this.addLineActor(line.id, lineActor, lineMapper, polyData);
        }

      });

      renderer?.getRenderWindow().render();

    },
  },
});