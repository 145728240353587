<script setup lang="ts">
import { ref, computed } from 'vue';
import { useLandmarkStore } from '../store/tools/landmarks';
import { useSplineStore } from '../store/tools/splines';
import { useAngleStore } from '../store/tools/angles';
import { useLineStore } from '../store/tools/lines';

const props = defineProps<{
  onCreateSpline: (name: string, isClosed: boolean) => void;
}>();

// Spline creation mode tracking and current spline ID
const creatingSpline = ref(false);
const creatingAngle = ref(false);
const creatingDistance = ref(false);
const selectedLandmarkA = ref(null);
const selectedLandmarkB = ref(null);
const selectedLandmarkC = ref(null);
const currentSplineId = ref<number | null>(null);

// Store instances
const landmarkStore = useLandmarkStore();
const splineStore = useSplineStore();
const angleStore = useAngleStore();
const lineStore = useLineStore();

// Computed properties to bind to the current spline's `name` and `closed` values
const splineName = computed({
  get: () => {
    if (currentSplineId.value !== null) {
      return splineStore.getSpline(currentSplineId.value)?.name || '';
    }
    return '';
  },
  set: (newName: string) => {
    if (currentSplineId.value !== null) {
      splineStore.updateSplineName(currentSplineId.value, newName);
    }
  },
});

const landmarks = computed(() => {
  return landmarkStore.landmarks.map(landmark => ({
    id: landmark.id,
    name: landmark.name,
  }));
});

const addControlActive = computed(() => {
  return !creatingSpline.value && !creatingAngle.value && !creatingDistance.value;
});

const isClosed = computed({
  get: () => {
    if (currentSplineId.value !== null) {
      return splineStore.getSpline(currentSplineId.value)?.closed || false;
    }
    return false;
  },
  set: (closed: boolean) => {
    if (currentSplineId.value !== null) {
      splineStore.setClosed(currentSplineId.value, closed);
    }
  },
});

// Starts spline creation and activates landmark-clicking mode
const startSplineCreation = () => {
  creatingSpline.value = true;
  currentSplineId.value = splineStore.createSplineByClicking(splineName.value, isClosed.value);
  landmarkStore.activateTool();
};

// Completes spline creation, resets state, and deactivates landmark-clicking mode
const completeSpline = () => {
  creatingSpline.value = false;
  landmarkStore.deactivateTool(); // Disable landmark-clicking
  currentSplineId.value = null;
  splineStore.deactivateSplineClicking();
};

const startAngleCreation = () => {
  creatingAngle.value = true;
};

const completeAngle = () => {

  if (selectedLandmarkA.value && selectedLandmarkB.value && selectedLandmarkC.value) {
    angleStore.addAngle(
      "New Angle",
      selectedLandmarkA.value,
      selectedLandmarkB.value,
      selectedLandmarkC.value,
      0.0
    ); // angle value set to 0.0 for now
  }

  creatingAngle.value = false;
};

const startDistanceCreation = () => {
  creatingDistance.value = true;
};

const completeDistance = () => {

  if (selectedLandmarkA.value && selectedLandmarkB.value) {
    lineStore.addLine(
      "New Distance",
      selectedLandmarkA.value,
      selectedLandmarkB.value,
      0.0
    ); // distance value set to 0.0 for now
  }

  creatingDistance.value = false;
};

</script>

<template>
  <v-card class="pt-2">
    <v-container>
      <v-row dense>

        <!-- Spline Creation Form -->
        <v-col cols="12" v-if="creatingSpline">
          <v-text-field
            label="Spline Name"
            dense
          />
          <v-switch
            label="Closed"
            inset
            dense
          ></v-switch>
          <v-btn color="primary" @click="completeSpline" class="w-100 mt-3">
            <v-icon class="mr-2">mdi-check</v-icon>
            Done Spline
          </v-btn>
        </v-col>

        <!-- Angle Creation Form -->
        <v-col cols="12" v-if="creatingAngle">
          <p>Select three landmarks (A, Center, and C). Center is the center of the angle, and A and C are the connected lines.</p>
          <br></br>
          <v-row dense>
            <v-col cols="4">
              <v-select
              v-model="selectedLandmarkA"
              label="A"
              :items="landmarks"
              item-value="id"
              item-title="name"
              dense
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
              v-model="selectedLandmarkB"
              label="Center"
              :items="landmarks"
              item-value="id"
              item-title="name"
              dense
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
              v-model="selectedLandmarkC"
              label="C"
              :items="landmarks"
              item-value="id"
              item-title="name"
              dense
              ></v-select>
            </v-col>
          </v-row>
          <v-btn color="primary" @click="completeAngle" class="w-100 mt-3">
            <v-icon class="mr-2">mdi-check</v-icon>
            Create Angle
          </v-btn>
        </v-col>

        <!-- Distance Creation Form -->
        <v-col cols="12" v-if="creatingDistance">
          <p>Select two landmarks (A and B) to measure the distance.</p>
          <br></br>
          <v-row dense>
            <v-col cols="6">
              <v-select
              v-model="selectedLandmarkA"
              label="B"
              :items="landmarks"
              item-value="id"
              item-title="name"
              dense
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
              v-model="selectedLandmarkB"
              label="B"
              :items="landmarks"
              item-value="id"
              item-title="name"
              dense
              ></v-select>
            </v-col>
          </v-row>
          <v-btn color="primary" @click="completeDistance" class="w-100 mt-3">
            <v-icon class="mr-2">mdi-check</v-icon>
            Create Distance
          </v-btn>
        </v-col>

        <!-- Control Buttons on One Line -->
        <v-col cols="12" v-if="addControlActive">
          <v-row dense>
            <v-col cols="4">
              <v-chip variant="outlined" class="w-100" @click="startSplineCreation">
                <v-icon class="mr-2">mdi-plus</v-icon>
                Spline
              </v-chip>
            </v-col>
            <v-col cols="4">
              <v-chip variant="outlined" class="w-100" @click="startAngleCreation">
                <v-icon class="mr-2">mdi-plus</v-icon>
                Angle
              </v-chip>
            </v-col>
            <v-col cols="4">
              <v-chip variant="outlined" class="w-100" @click="startDistanceCreation">
                <v-icon class="mr-2">mdi-plus</v-icon>
                Distance
              </v-chip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<style scoped>
.card-active {
  background-color: rgb(var(--v-theme-selection-bg-color));
  border-color: rgb(var(--v-theme-selection-border-color));
}
</style>