<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useSurface3DAnnotationStore } from '../store/tools/surfaceDrawings';
import { useModelStore } from '../store/datasets-models';

export default defineComponent({
  setup() {
    const annotationStore = useSurface3DAnnotationStore();
    const modelStore = useModelStore();
    const editingId = ref<number | null>(null);
    const newName = ref('');

    const annotations = computed(() => {
      return annotationStore.annotations.map(annotation => ({
        ...annotation,
        modelName: modelStore.getModelName(annotation.modelId)  // Get model name from modelStore
      }));
    });

    function remove(id: number) {
      annotationStore.removeAnnotation(id);
    }

    function startEditing(id: number, currentName: string) {
      editingId.value = id;
      newName.value = currentName;
    }

    function saveName(id: number) {
      if (newName.value.trim()) {
        annotationStore.updateAnnotationName(id, newName.value);
      }
      editingId.value = null;
    }

    function cancelEditing() {
      editingId.value = null;
    }

    function toggleVisibility(id: number) {
      annotationStore.toggleVisibility(id);
    }

    return {
      annotations,
      remove,
      editingId,
      newName,
      annotationStore,
      startEditing,
      saveName,
      cancelEditing,
      toggleVisibility,
    };
  },
});
</script>

<template>
  <v-list-item v-for="annotation in annotations" :key="annotation.id" lines="two">
    <template #prepend>
      <v-icon class="tool-icon">mdi-drawing</v-icon>
      <div class="color-dot mr-3" :style="{ backgroundColor: '#00f' }" />
    </template>
    <v-list-item-title v-bind="$attrs">
      <template v-if="editingId === annotation.id">
        <v-text-field v-model="newName" dense @blur="saveName(annotation.id)" @keydown.enter="saveName(annotation.id)" @keydown.esc="cancelEditing" />
      </template>
      <template v-else>
        <span @click="startEditing(annotation.id, annotation.name)">{{ annotation.name || 'Unnamed Annotation' }}</span>
      </template>
    </v-list-item-title>
    <v-list-item-subtitle>
      <v-row>
        <v-col>{{ annotation.modelName }}</v-col>
      </v-row>
    </v-list-item-subtitle>
    <template #append>
      <v-row>
        <v-btn icon="mdi-eye" variant="text" @click="toggleVisibility(annotation.id)">
          <v-icon>{{ annotationStore.getVisibleAnnotationIdByModelId(annotation.modelId) === annotation.id ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
          <v-tooltip location="top" activator="parent">Toggle Visibility</v-tooltip>
        </v-btn>
        <v-btn icon="mdi-delete" variant="text" @click="remove(annotation.id)">
          <v-icon>mdi-delete</v-icon>
          <v-tooltip location="top" activator="parent">Delete</v-tooltip>
        </v-btn>
      </v-row>
    </template>
  </v-list-item>
</template>

<style src="@/src/components/styles/utils.css"></style>

<style scoped>
.empty-state {
  text-align: center;
}

.color-dot {
  width: 24px;
  height: 24px;
  background: yellow;
  border-radius: 16px;
}

.tool-icon {
  margin-inline-end: 12px;
}
</style>