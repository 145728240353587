<template>
    <div class="vtk-container" ref="vtkContainerRef"></div>
  </template>
  
  <script lang="ts">
  import { ref, onMounted, onBeforeUnmount, defineComponent, PropType, render ,watch} from 'vue';
  import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';
  import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
  import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
  import vtkRenderWindowInteractor from '@kitware/vtk.js/Rendering/Core/RenderWindowInteractor';
  import { useModelStore } from '@/src/store/datasets-models';
  import { storeToRefs } from 'pinia'
  import { useSharedCameraStore } from '@/src/store/shared-camera';
  import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';
  import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera';
  
  export default defineComponent({
    name: 'ModelRenderer',
    props: {
      modelId: {
        type: String as PropType<string>,
        required: true,
      },
      mainRenderWindow: {
        type: Object,
        required: true,
      },
      mainRenderWindowView: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const vtkContainerRef = ref<HTMLElement | null>(null);
      const renderer = vtkRenderer.newInstance();
      const sharedCameraStore = useSharedCameraStore();
      const modelStore = useModelStore();
      const interactor = vtkRenderWindowInteractor.newInstance();
      const mapper = vtkMapper.newInstance();
      const actor = vtkActor.newInstance();
      const renderWindow = vtkRenderWindow.newInstance();
      const {sharedCamera,updated, initialized} = storeToRefs(sharedCameraStore);

      watch(
            [sharedCamera,updated],
            ([camera,]) => {
                if (renderer && renderWindow) {
                    renderer.setActiveCamera(camera);
                    renderWindow.render();
                }
            },
        { immediate: true }
        );

        const syncCamera = () => {
            if (renderer && renderWindow) {
                const activeCamera = renderer.getActiveCameraAndResetIfCreated();
                sharedCameraStore.updateSharedCamera(activeCamera);
            }
        };
  
      onMounted(() => {
        if (vtkContainerRef.value) {
          vtkContainerRef.value.id = `renderer-${props.modelId}`;
  
          // Get the PolyData from the model store using modelId prop
          const polyData = modelStore.dataIndex[props.modelId];
          if (!polyData) {
            console.error(`Model with id ${props.modelId} not found`);
            return;
          }

          props.mainRenderWindow.addRenderWindow(renderWindow);

          const renderWindowView = props.mainRenderWindowView.addMissingNode(renderWindow);
          renderWindow.addView(renderWindowView);
          renderWindowView.setContainer(vtkContainerRef.value);

          interactor.setView(renderWindowView);
          interactor.initialize();
          interactor.setInteractorStyle(
            vtkInteractorStyleTrackballCamera.newInstance()
          );
          interactor.setContainer(vtkContainerRef.value);
          interactor.onMouseMove(syncCamera);
          interactor.onMouseWheel(syncCamera);
  
          mapper.setInputData(polyData);
          mapper.setScalarVisibility(false); //Set it false for now
          
          actor.setMapper(mapper);
          renderer.addActor(actor);
          renderer.setBackground(0.4, 0.4, 0.4);
          renderer.resetCamera();

          renderWindow.addRenderer(renderer);

          renderWindow.render();

        }
      });
  
      onBeforeUnmount(() => {
        renderer.delete();
        renderWindow.delete();
        interactor.delete();
        mapper.delete();
        actor.delete();
      });
  
      return {
        vtkContainerRef,
      };
    },
  });
  </script>
  
  <style scoped>
  .vtk-container {
    width: 100%;
    height: 100%;
  }
  </style>
  