import { defineStore } from 'pinia';
import { useLineStore } from './lines';
import { useLandmarkStore } from './landmarks';
import { useAngleStore } from './angles';
import { useSplineStore } from './splines';
import { useSurface3DAnnotationStore } from './surfaceDrawings';
import { usePickerStore } from '../pickerStore';

export const useSurfaceMeasurementStore = defineStore('surfaceMeasurement', {
  state: () => ({
    refreshFlag: false,  // A variable to track the refresh state
  }),
  actions: {
    deleteAllLines() {
      const lineStore = useLineStore();
      lineStore.removeAllLines();
    },
    deleteAllLandmarks() {
      const landmarkStore = useLandmarkStore();
      landmarkStore.removeAllLandmarks();
    },
    deleteAllAngles() {
      const angleStore = useAngleStore();
      angleStore.removeAllAngles();
    },
    deleteAllSplines() {
      const splineStore = useSplineStore();
      splineStore.removeAllSplines();
    },
    deleteAllAnnotations() {
      const annotationStore = useSurface3DAnnotationStore();
      annotationStore.removeAllAnnotations();
    },
    deleteAllMeasurements() {
      this.deleteAllLines();
      this.deleteAllLandmarks();
      this.deleteAllAngles();
      this.deleteAllSplines();
      this.deleteAllAnnotations();
      this.refresh();
    },
    refresh() {
      const renderer = usePickerStore().renderer;
      const lineStore = useLineStore();
      lineStore.display(renderer);
      const landmarkStore = useLandmarkStore();
      landmarkStore.display(renderer);
      const angleStore = useAngleStore();
      angleStore.display(renderer);
      const splineStore = useSplineStore();
      splineStore.display(renderer);
    },
  },
});