import { defineStore } from 'pinia';
import axios, { AxiosInstance } from 'axios';
import cookies from 'js-cookie';
import {
  importDataSources,
  convertSuccessResultToDataSelection,
} from '@/src/io/import/importDataSources';
import { useDatasetStore } from '@/src/store/datasets';
import { fileToDataSource } from '../io/import/dataSource';
import { useLandmarkStore } from '@/src/store/tools/landmarks'; // Adjust the path as needed
import { useLineStore } from './tools/lines';
import { useAngleStore } from './tools/angles';
import { useModelStore } from './datasets-models';
import { useSplineStore } from './tools/splines';
import { useSurface3DAnnotationStore } from './tools/surfaceDrawings';
import { useSurfaceMeasurementStore } from './tools/surfaceMeasurements';

interface GirderConfig {
  version: string;
  timeout: number;
}

interface Case {
  name: string;
  id: string;
  meta: {
    input_items: string[];
    jobId: string;
    output_items: string[];
    task: string;
    anatomy: string;
  };
  state: string;
}

interface Progress {
  current: number;
  message: string;
  notificationId: string;
}

interface Job {
  jobId: string;
  log: string;
  progress : Progress;
  status: string;
}

interface FileGirderPath {
  id: string;
  path: string;
  type: string;
}

// Define the store
export const useGirderStore = defineStore('girder', {
  state: () => ({
    config: {
      version: 'v1',
      timeout: 120000,
    } as GirderConfig,
    host: 'https://v2202410237021291825.nicesrv.de',
    port: '',
    key: "",
    connected: false,
    rest: null as AxiosInstance | null,
    cases: {} as { [id: string]: Case },
    caseFolder: null as { _id: string; name: string } | null,
    loadedCase: null as Case | null,
    ALLOWED_STATES: ['state_empty', 'state_image_uploaded', 'state_image_processed'],
    inputItem: null,
    outputItem: null,
    girderPaths: [] as FileGirderPath[],
    datasetStore: useDatasetStore(),
  }),
  actions: {
    initializeAxios() {
      this.rest = axios.create({
        baseURL: `${this.host}:${this.port}/api/v1`,
        timeout: this.config.timeout,
      });
      this.addRequestDefaultTokenFromCookies();
    },
    addRequestDefaultTokenFromCookies() {
      this.rest.defaults.headers.common['Girder-Token'] = cookies.get('girderToken');
    },
    async login(apiKey: string, duration: number = 5) {
      if(apiKey === '') {
        return;
      }
      try {
        const { data } = await this.rest.post('/api_key/token', null, {
          params: {
            key: apiKey,
            duration
          }
        });
        // Save token in cookies
        cookies.set('girderToken', data.authToken.token, { sameSite: 'strict' });
        this.addRequestDefaultTokenFromCookies();
        this.connected = true;
        await this.searchCasesFolder();
        await this.fetchAnatomies();
        await this.listCases();

      } catch (error) {
        this.connected = false;
        console.error('Login with API key failed:', error);
      }
    },
    async searchCasesFolder() {
      try {
        const response = await this.rest.get('/folder', {
          params: {
            parentType: 'collection',
            text: 'cases',
            limit: 2,
            offset: 0,
            sort: 'lowerName',
            sortdir: 1,
          },
        });

        // Check if folder with name "cases" exists and store it
        const casesFolder = response.data.find(folder => folder.name === 'cases');
        if (casesFolder) {
          this.caseFolder = { _id: casesFolder._id, name: casesFolder.name };
        } else {
          console.warn("No 'cases' folder found.");
        }

      } catch (error) {
        console.error('Failed to search for "cases" folder:', error);
      }
    },
    setServerById(serverId: string | null = null) {
      this.host = `https://${serverId}.v2202410237021291825.nicesrv.de`;
    },
    setLoadedCaseByID(caseId: string) {
      this.loadedCase = this.cases[caseId] || null;
    },
    setLoadedCase(c: Case) {
      this.loadedCase = c;
    },
    async updateCaseState(caseId: string, newState: string) {

      if (!this.ALLOWED_STATES.includes(newState)) {
        console.error(`Invalid state. Allowed states are ${this.ALLOWED_STATES.join(", ")}`);
        return;
      }

      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      };

      try {
        const metadata = { state: newState };
        const { data } = await this.rest.put(`/folder/${caseId}/metadata`, metadata, config);
        // You might also want to update the local state here
      } catch (error) {
        console.error('Failed to update case state:', error);
      }
    },

    async createJob(caseId: string, task: string) {
        try {
          const config = {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            params: {
              title: `Job for Case ${caseId}`,
              type: task,
              public: false,
              kwargs: JSON.stringify({
                caseId: caseId,
                outputFolder: caseId
              }),
            },
          };

          const { data: createdJob } = await this.rest.post('/job', null, config);


          // Step 2: Update the job to set status to QUEUED
          const updateConfig = {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            params: {
               status: '1', // Assuming '1' represents QUEUED status
               progressTotal: 0,
               progressCurrent: 0,
               progressMessage: 'Job queued',
               notify: true
            },
          };

          const { data: updatedJob } = await this.rest.put(`/job/${createdJob._id}`, null, updateConfig);

          return updatedJob;
        } catch (error) {
          console.error('Failed to create job:', error);
          if (error.response) {
            const { data } = error.response;
            console.error('Error details (if available):', data);
          }
          throw error;
        }
    },

    async storeGirderPaths(loadResults: any[], allItems: any[]) {

      // Ensure that loadResults and allItems have the same length
      if (loadResults.length !== allItems.length) {
        console.error("Mismatch between loadResults and allItems length");
        return;
      }

      // Iterate over the results and items to store or update the mappings
      loadResults.forEach((result, index) => {
        if (result.ok && result.data.length > 0) {
          const dataID = result.data[0].dataID;
          const type = result.data[0].dataType;
          const path = allItems[index]._id;

          // Find the existing entry by the path
          const existingEntryIndex = this.girderPaths.findIndex(entry => entry.path === path);

          if (existingEntryIndex !== -1) {
            // If entry exists, update it
            this.girderPaths[existingEntryIndex] = { id: dataID, path, type };
          } else {
            // If entry doesn't exist, add a new one
            this.girderPaths.push({ id: dataID, path, type });
          }
        } else {
          console.warn(`Skipping invalid load result at index ${index}`);
        }
      });
    },

    async uploadFile(files: File[], folderID: string, task:string, progressCallback: (progress: number) => void) {

        let dicomFolderID = folderID; // ID for the folder to store DICOM files
        let totalUploaded = 0; // Total bytes uploaded
        const totalSize = files.reduce((acc, file) => acc + file.size, 0); // Total size of all files
        let inputItems: string[] = [];
        // Create an item for DICOM files if there are any DICOM files
        const dicomFiles = files.filter(file => file.name.endsWith('.dcm'));

        if (dicomFiles.length > 0) {
            try {
                const itemConfig = {
                    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
                };
                const { data } = await this.rest.post(`/item?folderId=${dicomFolderID}&name=DICOM`, {}, itemConfig);
                dicomFolderID = data._id; // Use the item ID as the folder ID for DICOM files
                inputItems.push(data._id); // Add DICOM item to input_items
            } catch (error) {
                console.error('Failed to create DICOM item Test:', error);
                throw error;
            }
        }

        // Upload each file to the appropriate item
        for (const file of files) {
            const isDicomFile = file.name.endsWith('.dcm');
            let itemFolderID = isDicomFile ? dicomFolderID : folderID; // Use DICOM item ID or the original folder ID

            if (!isDicomFile) {
                try {
                    const itemConfig = {
                        params: {
                            folderId: folderID,
                            name: file.name,
                        },
                        headers: { 'Content-Type': 'application/json' },
                    };
                    const { data } = await this.rest.post('/item', null, itemConfig);
                    itemFolderID = data._id;
                    inputItems.push(data._id);
                } catch (error) {
                    console.error(`Failed to create item for file ${file.name}:`, error);
                    continue; // Skip this file if the item creation failed
                }
            }

            try {
                const uploadConfig = {
                    params: {
                        parentType: 'item',
                        parentId: itemFolderID,
                        name: file.name,
                        size: file.size,
                        mimeType: file.type,
                    },
                    headers: { 'Content-Type': file.type },
                    onUploadProgress: (e: ProgressEvent) => {
                        totalUploaded += e.loaded;
                        const progress = Math.round((totalUploaded * 100) / totalSize);
                        progressCallback(progress);
                    },
                    timeout: 0,  // No timeout
                };
                await this.rest.post('/file', file, uploadConfig);
            } catch (error) {
                console.error(`Failed to upload file ${file.name}:`, error);
                continue; // Skip this file if the upload failed
            }
        }

        this.updateCaseState(folderID, this.ALLOWED_STATES[1]);

        // Create a job after successful upload
          // Update case metadata

        progressCallback(100); // Ensure progress is set to 100% at the end

        return inputItems;
    },

    async createJobWithInputItems(inputItems: string[], task: string, baseTask: string, folderID: string) {

      try {

        const jobData = await this.createJob(folderID, task);

        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata = {
          state: this.ALLOWED_STATES[1],
          input_items: JSON.stringify(inputItems),
          task: baseTask,
          jobId: jobData._id
        };
        await this.rest.put(`/folder/${folderID}/metadata`, metadata, metadataConfig);
      } catch (error) {
        console.error('Failed to update case metadata:', error);
      }

    },

    async updateMetadata(itemId: string,metadata: {}) {
      try {
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };

        // Send the PUT request to update metadata for the item
        await this.rest.put(`/item/${itemId}/metadata`, metadata, metadataConfig);

      } catch (error) {
        console.error(`Failed to update metadata for item ${itemId}:`, error);
      }
    },

    async fetchAnatomies() {
      try {
        const { data } = await this.rest.get('/case/anatomies'); // Ensure this endpoint is correct
        this.anatomies = data.map((anatomy: any) => ({
          text: anatomy.anatomy,
          value: anatomy.anatomy
        }));
      } catch (error) {
        console.error('Failed to fetch anatomies:', error);
      }
    },

    async getJob(jobId: string) {
      try {
        const config = { /* ... */ };
        const response = await this.rest.get(`/job/${jobId}`, config);

        if (response.status === 200) {
          const jobData = response.data;
          return transformJobData(jobData);
        }

        function transformJobData(jobData: any): Job {
          return {
            jobId: jobData._id,
            log: jobData.log,
            progress: jobData.progress,
            status: jobData.status,
          };
        }

        if (!response.data) {
          const emptyJob = {
            jobId: '',
            log: '',
            progress: {} as Progress,
            status: '',
          } as Job;
          return emptyJob;
        }
      } catch (error) {
        throw error;
      }
    },

    async listCases() {

      if(!this.caseFolder){
        console.warn('No case folder set');
        return;
      }

      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        params: {
          parentType: 'folder',
          parentId: this.caseFolder?._id,
        },
      };

      try {
        const { data } = await this.rest.get('/folder/', config);

        // Update the cases state with the new map
        this.cases = data.reduce((acc: { [id: string]: Case }, caseData: any) => {
          const inputItems = caseData.meta.input_items ? JSON.parse(caseData.meta.input_items) : [];
          const outputItems = caseData.meta.output_items ? JSON.parse(caseData.meta.output_items) : [];
          const jobId = caseData.meta.jobId || '';
          const anatomy = caseData.meta.anatomy || '';

          // Determine the state based on the conditions
          let state = 'state_empty';
          if (outputItems.length > 0) {
            state = 'state_image_processed';
          } else if (inputItems.length > 0 || jobId) {
            state = 'state_image_uploaded';
          }

          const caseObj: Case = {
            name: caseData.name,
            id: caseData._id,
            meta: {
              input_items: inputItems,
              jobId: jobId,
              output_items: outputItems,
              task: caseData.meta.task || '',
              anatomy: anatomy,
            },
            state: state, // Add the state property
          };
          acc[caseData._id] = caseObj;
          return acc;
        }, {});

      // Refresh loadedCase if it's found in the refreshed cases
      if (this.loadedCase) {
        const refreshedCase = this.cases[this.loadedCase.id];

        // Check if the loaded case has changed
        if (refreshedCase && JSON.stringify(refreshedCase) !== JSON.stringify(this.loadedCase)) {
          this.loadedCase = refreshedCase;
        }
      }

      } catch (error) {
        console.error('Failed to list cases:', error);
      }
    },
    async loadAnnotations(loadResults : any[], metadata: []) {
      const landmarkStore = useLandmarkStore();
      const lineStore = useLineStore();
      const splineStore = useSplineStore();
      const angleStore = useAngleStore();
      const modelStore = useModelStore();
      const allMeasurements = useSurfaceMeasurementStore();

      loadResults.forEach((loadResult, index) => {
        if (loadResult.ok && loadResult.data.length > 0) {
          const dataID = loadResult.data[0].dataID;

          // Find the corresponding metadata item by index
          const meta = metadata[index];

          const measurements = JSON.parse(meta.meta.measurements || '[]');

          // Set Model Color Annotations

          if(meta.meta.color){
            const color = JSON.parse(meta.meta.color || '[]');
            modelStore.setDisplayColor(dataID,{ r: color[0], g: color[1], b: color[2], a: color[3] });
          }

          if (meta.meta.visibility) {
            modelStore.setVisibility(dataID, meta.meta.visibility == 1 ? true : false);
          }

          if(meta.meta.gp_model) {
            modelStore.setDisplayColor(dataID,{ r: 0, g: 255, b: 255, a: 1 });
          }

          // Set Measurements

          measurements.forEach((measurement) => {
            const points = measurement.points3DForVisualization || [];
            const landmarkIds: number[] = [];
            const landmarksVisible = measurement.measurementType === "GeodesicDist"

            points.forEach((point, pointIndex: number) => {
              const landmark = landmarkStore.addLandmark(point.x, point.y, point.z, dataID, `${measurement.name}_point${pointIndex + 1}`,!landmarksVisible);
              landmarkIds.push(landmark.id);
            });

            if (measurement.measurementType === "Angle" && landmarkIds.length === 3) {
              angleStore.addAngle(measurement.name, landmarkIds[0], landmarkIds[1],landmarkIds[2],measurement.instanceValue);
            }

            if (measurement.measurementType === "EuclideanDist" && landmarkIds.length === 2) {
              lineStore.addLine(measurement.name, landmarkIds[0], landmarkIds[1], measurement.instanceValue);
            }

            if (measurement.measurementType === "GeodesicDist" && landmarkIds.length > 2) {
              splineStore.addSpline(measurement.name,landmarkIds,false,measurement.instanceValue);
            }

          });

          modelStore.refreshDisplay();
          allMeasurements.refresh();
        }
      });
    },
    async downloadCase(caseId: string, progressCallback: (progress: number) => void, skipDownloadedFiles: boolean = true) {
      try {
        const caseObj = this.cases[caseId];
        if (!caseObj) {
          console.error(`Case with ID ${caseId} not found.`);
          return;
        }

        const { input_items, output_items } = caseObj.meta;
        const allItems = [...input_items, ...output_items];

        // Filter out files that are already downloaded if skipDownloadedFiles is true
        const itemsToDownload = allItems.filter(itemId => {
          if (!skipDownloadedFiles) return true;
          // Check if the file path already exists in girderPaths
          return this.girderPaths.findIndex(entry => entry.path === itemId) === -1;
        });

        if (itemsToDownload.length === 0) {
          progressCallback(100); // No files to download, mark progress as complete
          return;
        }

        // Step 1: Fetch metadata for each item by ID
        const metadataResponses = await Promise.all(
          itemsToDownload.map(itemId => this.rest.get(`/item/${itemId}`))
        );
        const metadata = metadataResponses.map(response => response.data);

        const numberOfItems = itemsToDownload.length;
        let completedItems = 0;

        const downloadWithProgress = async (itemId: string) => {
          completedItems += 1; // Mark this item as fully downloaded after `downloadItem` completes
          const overallProgress = (completedItems / numberOfItems) * 50;
          progressCallback(overallProgress); // Update progress for completed item
          return this.downloadItem(itemId);
        };
      
        // Start all downloads and wait for them to complete
        const downloadedFiles = await Promise.all(
          itemsToDownload.map(itemId => downloadWithProgress(itemId))
        );

        // Step 3: Handle the downloaded files by converting them into data sources
        const datasources = await Promise.all(downloadedFiles.map(file => fileToDataSource(file)));

        progressCallback(80);

        try {
          const loadResults = await importDataSources(datasources);

          if (!loadResults || loadResults.length === 0) {
            throw new Error('Did not receive a load result');
          }

          progressCallback(85);

          this.loadAnnotations(loadResults, metadata);
          this.storeGirderPaths(loadResults, metadata);

          if (!loadResults[0].ok) {
            throw new Error('Result not ok');
          }

          progressCallback(90);

          // Step 4: Set primary selection based on the load results
          const imageResult = loadResults.find(
            result =>
              result.data &&
              result.data.length > 0 &&
              (result.data[0].dataType === 'dicom' || result.data[0].dataType === 'image')
          );

          if (imageResult) {
            const selection = convertSuccessResultToDataSelection(imageResult);
            this.datasetStore.setPrimarySelection(selection);
          }

          progressCallback(100);

        } catch (error) {
          console.warn('Error loading download:', error);
          return;
        }

      } catch (error) {
        console.error('Download failed:', error);
      }
      progressCallback(0); // Reset download progress
    },

    resetPaths() {
      this.girderPaths = [];
    },

    async downloadItem(itemId: string) {
      try {
        const config = {
          params: {
            format: 'zip',
            'content-disposition' : 'attachment',
          },
          responseType: 'blob',
          headers: { 'Content-Type': 'application/zip' }
        };

        const { data } = await this.rest.get(`/item/${itemId}/download`, config);
        return new File([data], `${itemId}.zip`);
      } catch (error) {
        console.error(`Download failed for item ${itemId}:`, error);
        throw error;
      }
    },

    async createCase(caseInfo: { name: string; anatomy: string; anatomicalSide: string; task: string }) {

      if(!this.caseFolder){
        console.warn('No case folder selected.');
        return; 
      }

      const { name, anatomy, anatomicalSide, task } = caseInfo;
      const parentFolderId = this.caseFolder._id; // The ID of the folder where cases are stored

      try {
        // Create a new folder
        const folderConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          params: {
            parentId: parentFolderId,
            name: name,
            description: `Case for ${anatomy}`,
          },
        };
        const { data: folderData } = await this.rest.post('/folder', null, folderConfig);

        // Add metadata to the folder
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata = {
          anatomy: anatomy,
          task: task,
          state: 'state_empty',
          input_items: '[]',
          output_items: '[]',
        };
        await this.rest.put(`/folder/${folderData._id}/metadata`, metadata, metadataConfig);

        // Return the created case data
        return {
          ...folderData,
          meta: metadata,
        };
      } catch (error) {
        console.error('Case creation failed:', error);
        throw error;
      }
    },
  },
});
