<template>
  <v-card>
    <v-card-title class="d-flex flex-row justify-center">
      <vol-view-full-logo />
    </v-card-title>
    <v-card-text>
      <h2 class="mt-2">About Shapemeans Insight</h2>
      <v-divider class="mb-2" />
      <p>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://shapemeans.com/"
        >
          <span>Shapemeans Insight</span>
        </a>
        is an advanced tech platform providing automated tools for a range of
        design and research tasks in medical imaging. Built on open-source frameworks and
        a custom integrated tech stack, it supports automated measurements powered by
        statistical shape models from
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://scalismo.org/"
        >
          <span>Scalismo</span>
        </a>.
        The Shapemeans platform combines a database backend, a user
        interface for visualization and interaction, and a worker system for
        processing tasks. This software version is a demo version of some
        of the current functionalities. Please contact Shapemeans GmbH
        to get access to further functionalities and cu
      </p>

      <br />

      Key functionalities include:

      <br />

      <ul>
        <li>Automated Fitting: Tools for automated segmentation and shape model fitting of medical images and shapes</li>
        <li>Batch Analysis: Capabilities for batch processing of shape data by applying complex measurements</li>
        <li>Measurement Tools: Automated measurements based data correspondence.</li>
        <li>Virtual Cohorts: Tools to create and manage virtual cohorts for research</li>
        <li>Shape reconstruction: Reconstruct a partial anatomy from 3D CT scans or 3D mesh surfaces</li>
      </ul>
      <br />
      Shapemeans Insight aims to facilitate a wide range of automated design and
      analysis tasks using medical images. It enables customized planning on individual patients, 
      aswell as on populations to plan and optimize designs.
      <br />
      <br />
      <h2 class="mt-2">Based Software</h2>
      <v-divider class="mb-2" />
      <ul>
        <li>
          <div class="d-flex flex-flow align-center text-no-wrap">
            <span>Scalismo: </span>
            <v-badge inline :content="versions['scalismo']" />
          </div>
        </li>
        <li>
          <div class="d-flex flex-flow align-center text-no-wrap">
            <span>Volview: </span>
            <v-badge inline :content="versions['volview']" />
          </div>
        </li>
        <li>
          <div class="d-flex flex-flow align-center text-no-wrap">
            <span>Girder: </span>
            <v-badge inline :content="versions['girder']" />
          </div>
        </li>
        <li>
          <div class="d-flex flex-flow align-center text-no-wrap">
            <span>vtk.js: </span>
            <v-badge inline :content="versions['vtk.js']" />
          </div>
        </li>
        <li>
          <div class="d-flex flex-flow align-center text-no-wrap">
            <span>itk-wasm: </span>
            <v-badge inline :content="versions['itk-wasm']" />
          </div>
        </li>
      </ul>

      <h2 class="mt-2">Worker Software Versions</h2>
      <v-divider class="mb-2" />
      <ul>
        <li>
          <span>Scalismo (Open Source): </span>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://scalismo.org/"
          >
            scalismo.org
          </a>
        </li>
        <li>
          <span>Shapemeans GmbH (Maintainer and Backend Framework): </span>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://shapemeans.com/"
          >
            shapemeans.com
          </a>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.close-button {
  position: absolute;
  right: 12px;
  top: 12px;
}
</style>

<script>
/* global __VERSIONS__ */

import { defineComponent } from 'vue';
import { useDisplay } from 'vuetify';
import VolViewFullLogo from './icons/VolViewFullLogo.vue';

export default defineComponent({
  name: 'AboutBox',
  components: {
    VolViewFullLogo,
  },
  setup() {
    const display = useDisplay();

    return {
      mobile: display.xs,
      versions: {
        girder: "3.2.6",
        scalismo: "0.92",
        volview: __VERSIONS__.volview,
        'vtk.js': __VERSIONS__['vtk.js'],
        'itk-wasm': __VERSIONS__['itk-wasm'],
      },
    };
  },
});
</script>
