import { defineStore } from 'pinia';
import { useLandmarkStore } from './landmarks';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkPoints from '@kitware/vtk.js/Common/Core/Points';
import vtkCellArray from '@kitware/vtk.js/Common/Core/CellArray';

interface Angle {
  id: number;
  name: string;
  landmarkAId: number;
  landmarkBId: number;
  landmarkCId: number;
  value: number;
}

interface AngleState {
  angles: Angle[];
  angleActors: Map<number, vtkActor>;
  nextId: number;
  updated: boolean;
  hoveredAngle: number | null;
}

export const useAngleStore = defineStore('angles', {
  state: (): AngleState => ({
    angles: [],
    angleActors: new Map<number, vtkActor>(),
    nextId: 1,
    updated: false,
    hoveredAngle: null,
  }),
  actions: {
    addAngle(name: string, landmarkAId: number, landmarkBId: number, landmarkCId: number, value: number) {
      const id = this.nextId++;
      const angle: Angle = { id, name, landmarkAId, landmarkBId, landmarkCId , value};
      this.angles.push(angle);
      this.updated = !this.updated;
      return angle;
    },
    removeAllAngles() {
      // Get all angle IDs
      const angleIds = this.angles.map(angle => angle.id);

      // Remove each angle using the existing removeAngle method
      angleIds.forEach(id => {
        this.removeAngle(id);
      });

    },
    addAngleActor(id: number, actor: vtkActor) {
      this.angleActors.set(id, actor);
    },
    getAngles() {
      return this.angles;
    },
    getAngleActors() {
      return this.angleActors;
    },
    removeAngleActor(id: number) {
      this.angleActors.delete(id);
    },
    getAngleLandmarks(angleId: number) {
      const landmarkStore = useLandmarkStore();
      const angle = this.angles.find(angle => angle.id === angleId);
      if (!angle) return [];
      return [
        landmarkStore.getLandmarks().find(landmark => landmark.id === angle.landmarkAId),
        landmarkStore.getLandmarks().find(landmark => landmark.id === angle.landmarkBId),
        landmarkStore.getLandmarks().find(landmark => landmark.id === angle.landmarkCId),
      ].filter(Boolean); // Filter out any undefined landmarks
    },
    updateAngleName(id: number, newName: string) {
      const angle = this.angles.find(angle => angle.id === id);
      if (angle) {
        angle.name = newName;
        this.updated = !this.updated;
      }
    },
    update() {
      this.updated = !this.updated;
    },
    removeAngle(angleId: number) {

      this.angles = this.angles.filter(angle => angle.id !== angleId);
      this.updated = !this.updated;
    },
    updateHoveredAngle(id: number | null) {
      // Reset the previous hovered landmark
      if (this.hoveredAngle !== null) {
        const actor = this.angleActors.get(this.hoveredAngle);
        actor?.getProperty().setLineWidth(1.0);
        actor?.modified();
      }

      // Update the hovered landmark
      this.hoveredAngle = id;

      // Highlight the new hovered landmark
      if (id !== null) {
        const actor = this.angleActors.get(id);
        actor?.getProperty().setLineWidth(3.0);
        actor?.modified();
      }
      
      this.updated = !this.updated;

    },
    display(renderer: any) {
      // Remove old angle actors if they exist
      this.angleActors.forEach((actors, angleId) => {
        if (!this.angles.some(angle => angle.id === angleId)) {
          // If the angle ID no longer exists in the angles array, process each actor
          renderer?.removeActor(actors); // Remove each actor from the renderer
          this.removeAngleActor(angleId); // Clear the actors array from the map
        }
      });

      // Add new angle actors
      this.angles.forEach(angle => {
        if (!this.angleActors.has(angle.id)) {
          const landmarks = this.getAngleLandmarks(angle.id);
          if (landmarks.length !== 3) {
            return;
          }

          const [a, b, c] = landmarks;
          const radius = 5;
          const resolution = 50;

          // Create lines from a to b and c to b
          const points = vtkPoints.newInstance();
          points.insertNextPoint(a?.x, a?.y, a?.z);
          points.insertNextPoint(b?.x, b?.y, b?.z);
          points.insertNextPoint(c?.x, c?.y, c?.z);

          const cells = vtkCellArray.newInstance();
          cells.insertNextCell([0, 1]); // Line from A to B
          cells.insertNextCell([2, 1]); // Line from C to B

          const linePolyData = vtkPolyData.newInstance();
          linePolyData.setPoints(points);
          linePolyData.setLines(cells);

          const lineMapper = vtkMapper.newInstance();
          lineMapper.setInputData(linePolyData);

          const lineActor = vtkActor.newInstance();
          lineActor.setMapper(lineMapper);
          lineActor.getProperty().setColor(0.0, 1.0, 1.0); // Cyan angle lines
          lineActor.setPickable(false);

          renderer?.addActor(lineActor);

          this.addAngleActor(angle.id, lineActor);
        }
      });

      renderer?.getRenderWindow().render();

    },
  },
});
