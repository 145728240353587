import { defineStore } from 'pinia';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';

interface Landmark {
  id: number;
  x: number;
  y: number;
  z: number;
  listed: boolean;
  visible: boolean;
  parentMeshId: string;
  name: string;
}

interface State {
  landmarks: Landmark[];
  selectedLandmarks: number[];
  hoveredLandmark: number | null;
  actors: Map<number, vtkActor>;
  nextId: number;
  updated: boolean;
  active: boolean;
}

export const useLandmarkStore = defineStore('landmarks', {
  state: (): State => ({
    landmarks: [],
    selectedLandmarks: [],
    hoveredLandmark: null,
    actors: new Map<number, vtkActor>(),
    nextId: 1,
    updated: false,
    active: false,
  }),
  actions: {
    display(renderer: any) {

      // Remove actors not in landmarks
      this.actors.forEach((actor, id) => {
        const visible = this.landmarks.find(f => f.id === id)?.visible
        if (visible == undefined || !visible) {
          renderer?.removeActor(actor);
          this.actors.delete(id);
        }
      });
      // Add actors for landmarks
      this.landmarks.forEach((landmark) => {
        if (!this.actors.has(landmark.id) && landmark.visible) {
          const sphere = vtkSphereSource.newInstance();
          sphere.setCenter(landmark.x, landmark.y, landmark.z);
          sphere.setRadius(2.0);
          const sphereMapper = vtkMapper.newInstance();
          sphereMapper.setInputData(sphere.getOutputData());
          const sphereActor = vtkActor.newInstance();
          sphereActor.setMapper(sphereMapper);
          sphereActor.setPickable(false);
          sphereActor.getProperty().setColor(0.0, 1.0, 0.0); // Default color: green
          this.addActor(landmark.id, sphereActor);
          renderer?.addActor(sphereActor);
        }
      });

      renderer?.getRenderWindow().render();
    },
    // In your landmark store
    getNextLandmarkName(): string {
      const usedNames = new Set(this.landmarks.map(l => l.name));
      const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for (let i = 0; i < alphabet.length; i++) {
        const name = alphabet[i];
        if (!usedNames.has(name)) {
          return name;
        }
      }

      // If all single letters are used, start combining them with numbers
      let suffix = 1;
      while (true) {
        for (let i = 0; i < alphabet.length; i++) {
          const name = alphabet[i] + suffix;
          if (!usedNames.has(name)) {
            return name;
          }
        }
        suffix++;
      }
    },
    addLandmark(x: number, y: number, z: number, parentMeshId: string, name: string = "", visible: boolean = true) {
      const id = this.nextId++;
      if (!name.trim()) {
        name = this.getNextLandmarkName();
      }
      const landmark: Landmark = { id, x, y, z, visible, parentMeshId, name , listed: true};
      
      this.landmarks.push(landmark);

      this.update();

      return landmark;
    },
    addActor(id: number, actor: vtkActor) {
      this.actors.set(id, actor);
    },
    getLandmarks() {
      return this.landmarks;
    },
    getActors() {
      return this.actors;
    },
    update() {
      this.updated = !this.updated;
    },
    updateLandmarkName(id: number, newName: string) {
      const landmark = this.landmarks.find(landmark => landmark.id === id);
      if (landmark) {
        landmark.name = newName;
        this.updated = !this.updated;
      }
    },
    getLandmark(id: number) {
      return this.landmarks.find(landmark => landmark.id === id);
    },
    removeActor(id: number) {
      this.actors.delete(id);
    },
    removeLandmark(id: number) {

      // Remove the landmark
      this.landmarks = this.landmarks.filter(landmark => landmark.id !== id);
      this.update();

    },
    removeAllLandmarks() {
      // Get all angle IDs
      const landmarkIds = this.landmarks.map(landmark => landmark.id);
    
      // Remove each angle using the existing removeAngle method
      landmarkIds.forEach(id => {
        this.removeLandmark(id);
      });
    
      // Mark the state as updated
      // this.updated = !this.updated;
    },
    updateHoveredLandmark(id: number | null) {
      // Reset the previous hovered landmark
      if (this.hoveredLandmark !== null) {
        const actor = this.actors.get(this.hoveredLandmark);
        if (actor) {
          // Reset actor color and scale
          actor.getProperty().setColor(0.0, 1.0, 0.0); // Assuming white is the default color
        }
      }

      // Update the hovered landmark
      this.hoveredLandmark = id;

      // Highlight the new hovered landmark
      if (id !== null) {
        const actor = this.actors.get(id);
        if (actor) {
          // Set actor color to blue
          actor.getProperty().setColor(0.0, 0.0, 1.0);

        }
      }

      // Trigger a re-render
      this.update();
    },
    activateTool() : boolean {
      this.active = true;
      return true;
    },
    deactivateTool() {
      this.active = false;
    },
    serializeJSON(modelID: string): string {
      const filteredLandmarks = this.landmarks
        .filter(landmark => landmark.parentMeshId === modelID)
        .map(landmark => ({
          id: landmark.name, // Using name as id
          coordinates: [landmark.x, landmark.y, landmark.z],
          uncertainty: {
            stddevs: [1, 1, 1],
            pcvectors: [
              [1, 0, 0],
              [0, 1, 0],
              [0, 0, 1],
            ],
          },
          visible: landmark.visible,
        }));

      return JSON.stringify(filteredLandmarks);
    },
    updateSelectedLandmarks(selected: number[]) {
      // Deselect previously selected landmarks
      this.selectedLandmarks.forEach(id => {
        const actor = this.actors.get(id);
        if (actor) {
          // Reset actor color and scale
          actor.getProperty().setColor(0.0, 1.0, 0.0); // Assuming green is the default color
          actor.setScale(1.0, 1.0, 1.0); // Reset scale
        }
      });

      // Update selected landmarks
      this.selectedLandmarks = selected;

      // Select new landmarks
      this.selectedLandmarks.forEach(id => {
        const actor = this.actors.get(id);
        if (actor) {
          // Set actor color to red
          actor.getProperty().setColor(1.0, 0.0, 0.0);

          // Scale the actor to be slightly bigger
          actor.setScale(1.2, 1.2, 1.2);
        }
      });

      // Trigger a re-render
      this.updated = !this.updated;
    },
  },
});