import { defineStore } from 'pinia';
import { useLandmarkStore } from './landmarks';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkSpline3D from '@kitware/vtk.js/Common/DataModel/Spline3D';
import { useSurfaceMeasurementStore } from './surfaceMeasurements';
import { render, watch } from 'vue';

interface Spline {
  id: number;
  name: string;
  landmarkIds: number[];
  closed: boolean;
  length: number;
  refresh: boolean;
}

interface State {
  splines: Spline[];
  splineActors: Map<number, { actor: vtkActor, mapper: vtkMapper, polyData: vtkPolyData }>;
  nextId: number;
  updated: boolean;
  hoveredSpline: number | null;
  splineClickingActive: boolean;
  activeSplineId: number | null;
  watcherInitialized: boolean;
}

export const useSplineStore = defineStore('splines', {
  state: (): State => ({
    splines: [],
    splineActors: new Map<number, { actor: vtkActor, mapper: vtkMapper, polyData: vtkPolyData }>(),
    nextId: 1,
    updated: false,
    hoveredSpline: null,
    splineClickingActive: false,
    activeSplineId: null,
    watcherInitialized: false,
  }),
  actions: {
    addSpline(name: string, landmarkIds: number[], closed: boolean, length: number) {
      const id = this.nextId++;
      const spline: Spline = { id, name, landmarkIds, closed , length, refresh: true };
      this.splines.push(spline);
      this.updated = !this.updated;
      return spline;
    },
    getSpline(id: number): Spline | null {
      return this.splines.find(s => s.id === id) || null;
    },
    setClosed(id: number, closed: boolean) {
      const spline = this.splines.find(s => s.id === id);
      if (spline) {
        spline.closed = closed;
        spline.refresh = true;
        this.updated = !this.updated;
      }
    },
    createSplineByClicking(name: string, closed: boolean) {
      const newSpline = this.addSpline(name, [], closed, 0);
      this.activeSplineId = this.splines.findIndex(spline => spline.id === newSpline.id);
      console.log(this.activeSplineId);
      this.splineClickingActive = true;

      if(!this.watcherInitialized) {
        const landmarkStore = useLandmarkStore();

        watch(
          () => landmarkStore.landmarks.length,
          (newLength, oldLength) => {
            // Immediately exit if spline clicking is not active
            if (!this.splineClickingActive || this.activeSplineId === null) {
              return;
            }
  
            // If a new landmark was added, add it to the current spline
            if (newLength > oldLength) {
              const newLandmark = landmarkStore.landmarks[newLength - 1];
              newLandmark.visible = true;
              newLandmark.listed = false;
              this.splines[this.activeSplineId].landmarkIds.push(newLandmark.id);
              this.splines[this.activeSplineId].refresh = true;
              this.updated = !this.updated;
            }
          }
        );
        this.watcherInitialized = true;
      }

      return newSpline.id;
    },
    deactivateSplineClicking() {
      const landmarkStore = useLandmarkStore();
      if(this.activeSplineId != null){
        this.splines[this.activeSplineId].landmarkIds.map((landmarkId) => {
          const lm = landmarkStore.getLandmark(landmarkId);
          if(lm) {
            lm.visible = false;
          }
        });
      }
      landmarkStore.update();
      this.splineClickingActive = false;
      this.activeSplineId = null;
    },
    addSplineActor(id: number, actor: vtkActor, mapper: vtkMapper, polyData: vtkPolyData) {
      this.splineActors.set(id, { actor, mapper, polyData });
    },
    getSplines() {
      return this.splines;
    },
    getSplineActors() {
      return this.splineActors;
    },
    removeSplineActor(id: number) {
      this.splineActors.delete(id);
    },
    updateHoveredSpline(id: number | null) {

      // Reset the previous hovered landmark
      if (this.hoveredSpline !== null) {
        const actor = this.splineActors.get(this.hoveredSpline)?.actor;
        actor?.getProperty().setLineWidth(3.0);
        actor?.modified();
      }

      // Update the hovered landmark
      this.hoveredSpline = id;

      // Highlight the new hovered landmark
      if (id !== null) {
        const actor = this.splineActors.get(id)?.actor;
        actor?.getProperty().setLineWidth(6.0);
        actor?.modified();
      }

      this.updated = !this.updated;

    },
    removeSpline(id: number) {
      const splineIndex = this.splines.findIndex(spline => spline.id === id);
      if (splineIndex !== -1) {
        // Remove associated actors
        const { actor, mapper, polyData } = this.splineActors.get(id) || {};
        if (actor) actor.delete();
        if (mapper) mapper.delete();
        if (polyData) polyData.delete();
        
        this.splineActors.delete(id);
        const landmarkStore = useLandmarkStore();
        this.splines[splineIndex].landmarkIds.forEach(landmarkId => {
          landmarkStore.removeLandmark(landmarkId);
        });
        this.splines.splice(splineIndex, 1);
        this.updated = !this.updated;
      }
    },
    removeAllSplines() {
      // Get all spline IDs
      const splineIds = this.splines.map(spline => spline.id);
    
      // Remove each spline using the existing removeSpline method
      splineIds.forEach(id => {
        this.removeSpline(id);
      });
  
    },
    getSplineLandmarks(splineId: number) {
      const landmarkStore = useLandmarkStore();
      const spline = this.splines.find(spline => spline.id === splineId);
      if (!spline) return [];
      return spline.landmarkIds.map(id => landmarkStore.getLandmarks().find(landmark => landmark.id === id));
    },
    updateSplineName(id: number, newName: string) {
      const s = this.splines.find(spline => spline.id === id);
      if (s) {
        s.name = newName;
        this.updated = !this.updated;
      }
    },
    // Display splines

    display(renderer: any) {
      // Remove actors for splines that no longer exist
      this.splineActors.forEach(({ actor, mapper, polyData }, splineId) => {
        const splineExists = this.splines.some(spline => spline.id === splineId);
        if (!splineExists) {
          renderer?.removeActor(actor);
          actor.delete();
          mapper.delete();
          polyData.delete();
          this.splineActors.delete(splineId);
        }
      });
    
      // Add or update spline actors
      this.splines.forEach(spline => {
        const actorEntry = this.splineActors.get(spline.id);
        const landmarks = this.getSplineLandmarks(spline.id).filter(Boolean);
        const points = landmarks.map(({ x, y, z }) => [x, y, z]);
    
        if (spline.closed && points.length > 0) {
          // Ensure the spline closes by appending the first point to the end
          points.push([points[0][0], points[0][1], points[0][2]]);
        }
    
        if (points.length < 2) {
          return;
        }
    
        // Create or update the spline points based on `spline.refresh`
        const splineInstance = vtkSpline3D.newInstance({ close: spline.closed });
        splineInstance.computeCoefficients(points);
    
        const numPoints = 100;
        const outPoints = new Float32Array(numPoints * 3);
        const intervalCount = points.length - 1;
    
        for (let i = 0; i < numPoints; i++) {
          const t = i / (numPoints - 1);
          const intervalIndex = Math.floor(t * intervalCount);
          const intervalT = (t * intervalCount) - intervalIndex;
          const [x, y, z] = splineInstance.getPoint(intervalIndex, intervalT);
          outPoints[i * 3] = x;
          outPoints[i * 3 + 1] = y;
          outPoints[i * 3 + 2] = z;
        }
    
        if (actorEntry && spline.refresh) {
          // Update existing polyData points instead of creating a new actor
          actorEntry.polyData.getPoints().setData(outPoints, 3);
          actorEntry.polyData.modified();  // Trigger a re-render
          spline.refresh = false;          // Reset the refresh flag
        } else if (!actorEntry) {
          // Create a new actor, mapper, and polyData if one doesn't already exist
          const polyData = vtkPolyData.newInstance();
          polyData.getPoints().setData(outPoints, 3);
    
          const lines = new Uint32Array(numPoints + 1);
          lines[0] = numPoints;
          for (let i = 0; i < numPoints; i++) {
            lines[i + 1] = i;
          }
          polyData.getLines().setData(lines);
    
          const splineMapper = vtkMapper.newInstance();
          splineMapper.setInputData(polyData);
    
          const splineActor = vtkActor.newInstance();
          splineActor.setMapper(splineMapper);
          splineActor.getProperty().setColor(0.5, 0.4, 2.0);
          splineActor.getProperty().setLineWidth(3.0);
    
          renderer?.addActor(splineActor);
          this.addSplineActor(spline.id, splineActor, splineMapper, polyData);
        }
      });
    
      renderer?.getRenderWindow().render();
    },
  }
});